import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import './Footer.css'

import creditCardsIcon from '../../../utils/assets/footer/credit-cards-icon.png';
import docChatIcon from '../../../utils/assets/logo/pet-lovers-icon.png';
import emailIcon from '../../../utils/assets/footer/email-icon.png';
import customerServiceIcon  from '../../../utils/assets/footer/customer-service-icon.png'



const Footer = props => {
    const handleHomeClick = ( sectionId ) => {
        window.scroll(0,0);
        const sectionElement = document.getElementById(sectionId);
        localStorage.setItem('sectionName', sectionId);
        if (sectionElement) {
            const windowHeight = window.innerHeight;
            const docScrollTop = window.scrollY || document.documentElement.scrollTop;
            const sectionTop = sectionElement.getBoundingClientRect().top + docScrollTop;
            const centeredPosition = sectionTop - (windowHeight / 2);
            const scrollToPosition = centeredPosition + 310; 
            window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
        } else {
            console.log("The section does not exist");
            localStorage.setItem('sectionName',sectionId);
        }
        
      };

      const handleGoTo = () =>{
        sessionStorage.setItem('sectionName', 'home');
        window.location.href = '/cancel-service';
      }
    return (
        <footer className="Footer__footer">

            <div className="Footer__main-footer-container">
                <div className="Footer__left-container" >
                    <div className="Footer__left-logo-container">
                        <img src={docChatIcon} alt="clubSaveIcon" className="Footer__logo" />
                    </div>
                    <div className="Footer__left-contact-container" >
                        <img src={customerServiceIcon} alt="customerSeviceIcon" className="Footer__icons" />
                        <p>
                        info@petsandsavings.com
                        </p>

                    </div>
                    <div className="Footer__left-email-container">
                        <img src={emailIcon} alt="emailIcon" className="Footer__icons" />
                       
                        <p>1-855-413-1910</p>
                    </div>
                </div>

                <div className="Footer__center-container" >
                    <div className="Footer__center-menu-container">
                        <nav className="Footer__center-nav">
                            <ul>
                                <li><a href="refund-policy" onClick={() => handleHomeClick('home')}>REFUND-POLICY</a></li>
                                <li><Link to="/privacy-policy" onClick={() => handleHomeClick('privacy-policy')} >PRIVACY POLICY</Link></li>
                                <li><a href="terms-of-service" onClick={() => handleHomeClick('home')}>TERMS OF SERVICE</a></li>
                                <li><a href="cancel-service" onClick={() => handleHomeClick('home')}>CANCEL SERVICE</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="Footer__right-container" >
                    <div className="Footer__right-menu-container">
                        <nav className="Footer__right-nav">
                            <ul >
                                <li><Link to="/"  onClick={() => handleHomeClick('home')}>HOME</Link></li>
                                <li><Link to="/about-us"  onClick={() => handleHomeClick('about-us')}>ABOUT US</Link></li>
                                <li><Link to="/price"  onClick={() => handleHomeClick('price')}>PRICE</Link></li>
                                <li><Link to="/contact-us"  onClick={() => handleHomeClick('contact-us')}>CONTACT</Link></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>

            <div className="MainContainer__right-separator"></div>
            
            <div className="Footer__main-legal-description-container">
                <div className="Footer__legal-description-container">
                    <p className="text-left">
                        Dear Member, you will receive a text or email notification 48 hours prior to your 
                        monthly bill date. If you do not wish to continue with your enrollment, you may call our 
                        Customer Service Department 1-855-413-1910 or click <a href="/cancel-service" onClick={handleGoTo}>here </a>to cancel and submit your request.
                    </p>
                </div>
                <div className="Footer__right-and-cards-icon-container">
                    <div className="Footer__copy-right-container">
                        <p>
                            <strong>Pet Assurance LLC © 2025 All Rights Reserved</strong>
                        </p>
                    </div>
                    <div className="Footer__card-icon-container">
                        <div className="Footer__credit-card-icons">
                            <img src={creditCardsIcon} alt="creditCardIcon" />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;